<template>
    <div class="edit-note-page">
        <el-input type="textarea" placeholder="请输入编辑备注内容" v-model="editForm.note" :autosize="{ minRows: 4 }">
        </el-input>
        <div class="footer-btn">
            <el-button class="confirm-btn" size="small" type="primary" @click="handleConfirmBtn">确认</el-button>
            <el-button class="cancel-btn" size="small" type="info" @click="$emit('closeDialog', false)">取消</el-button>
        </div>
    </div>
</template>

<script>
import { editIntentionCustomerCenterNoteAction } from '@/common/api/customerCenter/intentionCustomerCenter.js';
export default {
    name: 'editNoteDialog',
    props: {
        editNoteData: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
        editForm() {
            return this.editNoteData;
        }
    },
    methods: {
        handleConfirmBtn() {
            let params = {
                ID: this.editForm.id,
                Remarks: this.editForm.note
            };
            editIntentionCustomerCenterNoteAction(params)
                .then(res => {
                    let { code, msg } = res;
                    if (code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${msg}`,
                            center: true
                        });
                        this.$emit('closeDialog', false);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.edit-note-page {
    .footer-btn {
        margin-top: 30px;
        text-align: center;
    }
}
</style>
