<template>
  <el-dialog
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :width="width"
    v-model="dialogVisible"
    center
  >
    <slot></slot>
    <template #footer>
        <span v-if="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
    </template>
    
  </el-dialog>
</template>

<script>
export default {
    emits: ['showDialog'],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '标题',
        },
        width: {
            type: String,
            default: '30%',
        },
        footer: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        dialogVisible: {
            get(){
                return this.show
            },
            set(val){
                this.$emit('showDialog', val);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
</style>