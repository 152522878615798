<template>
    <div class="intention-customer-center-page">
        <div class="intention-customer-center-header">
            <div class="header-left">
                <el-select size="small" v-model="IntentionCustomerForm.status" clearable placeholder="请选择状态">
                    <el-option v-for="item in intentionCustomerStatusList" :key="item.id" :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-input size="small" v-model="IntentionCustomerForm.searchVal" placeholder="请输入企业名称" clearable>
                </el-input>
                <el-button size="small" type="primary" @click="handleSearch">查询</el-button>
            </div>
            <div class="header-right">
                <!-- <el-button size="small" type="primary" @click="handleExport">导出</el-button>
                <span style="margin: 0 20px; top: 2px">|</span> -->
                <span style="top: 3px; margin-right: 20px">共{{ page.count }}条</span>
            </div>
        </div>
        <div class="intention-customer-center-table">
            <el-table v-loading="loading" :header-cell-style="{ background: '#F2F2F2' }"
                :data="IntentionCustomerDataList" border align="center" style="width: 100%">
                <el-table-column label="序号" width="60" type="index" :index="indexMethod" align="center">
                </el-table-column>
                <el-table-column prop="enterpriseName" label="企业名称" align="center"></el-table-column>
                <el-table-column prop="belongsRegion" label="所属地区" align="center"></el-table-column>
                <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                <el-table-column prop="bindPhone" label="绑定手机号" align="center"></el-table-column>
                <el-table-column prop="time" label="表单填写时间" align="center" width="160"></el-table-column>
                <el-table-column prop="note" label="客户状态" align="center">
                    <template v-slot="scope">
                        <div :class="[scope.row.status === 3 ? 'blue-color' : '']">
                            {{ customerStatusName(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="note" label="备注" align="center">
                    <template v-slot="scope">
                        <div>{{ scope.row.note ? scope.row.note : '/' }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="180px">
                    <template v-slot="scope">
                        <el-button @click="handleEditNoteBtn(scope.row)" type="text" size="small">编辑备注</el-button>
                        <el-button @click="handleChangeStatus(scope.row)" type="text" size="small">更改状态</el-button>
                        <el-button @click="handleDeleteBtn(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align: right; margin-top: 30px" v-if="IntentionCustomerDataList.length > 0">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="page.page" :page-sizes="[10, 30, 40, 50]" :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="page.count">
            </el-pagination>
        </div>
        <!-- 编辑备注弹窗 -->
        <dialogFrame width="450px" :show="dialogEditNoteVisible" @showDialog="showDialog" :title="'编辑备注'"
            :footer="false">
            <editNoteDialog :editNoteData="editNoteData" @closeDialog="closeDialog"></editNoteDialog>
        </dialogFrame>
        <!-- 更改状态弹窗 -->
        <dialogFrame width="460px" :show="dialogChangeStatusVisible" @showDialog="showDialog" :title="'更改状态'"
            :footer="false">
            <changStatusDialog :changeStatusData="changeStatusData"
                :intentionCustomerStatusList="intentionCustomerStatusList" @closeDialog="closeDialog">
            </changStatusDialog>
        </dialogFrame>
    </div>
</template>

<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import editNoteDialog from '@/views/customerCenter/intentionCustomerCenter/components/editNoteDialog.vue';
import changStatusDialog from '@/views/customerCenter/intentionCustomerCenter/components/changeStatusDialog.vue';
import {
    getIntentionCustomerCenterList,
    downloadIntentionCustomerCenterListAction,
    IntentionCustomerCenterDeleteAction
} from '@/common/api/customerCenter/intentionCustomerCenter.js';
export default {
    components: {
        dialogFrame,
        editNoteDialog,
        changStatusDialog
    },
    data() {
        return {
            IntentionCustomerForm: {
                // 状态
                status: '',
                searchVal: ''
            },
            page: {
                page: 1,
                pageSize: 10,
                count: 0
            },
            // 状态列表
            intentionCustomerStatusList: [],
            // 表格数据
            IntentionCustomerDataList: [],
            loading: false,
            // 编辑备注弹窗
            dialogEditNoteVisible: false,
            editNoteData: {},
            // 更改状态弹窗
            dialogChangeStatusVisible: false,
            changeStatusData: {}
        };
    },
    created() {
        this.queryIntentionCustomerCenterData();
    },
    methods: {
        showDialog(val) {
            this.dialogEditNoteVisible = val;
            this.dialogChangeStatusVisible = val;
        },
        closeDialog(val) {
            this.dialogEditNoteVisible = val;
            this.dialogChangeStatusVisible = val;
            this.queryIntentionCustomerCenterData();
        },
        queryIntentionCustomerCenterData() {
            this.loading = true;
            let params = {
                Status: this.IntentionCustomerForm.status,
                EnterpriseName: this.IntentionCustomerForm.searchVal,
                page: this.page.page,
                Len: this.page.pageSize
            };
            getIntentionCustomerCenterList(params)
                .then(res => {
                    let { code, Status, Customer, count } = res;
                    if (code === 200) {
                        this.intentionCustomerStatusList = Status;
                        this.intentionCustomerStatusList.unshift({
                            id: 0,
                            name: '全部'
                        });
                        this.IntentionCustomerDataList = Customer.map(item => {
                            return {
                                id: item.ID,
                                enterpriseName: item.EnterpriseName,
                                name: item.RealName,
                                belongsRegion: item.Areas,
                                phone: item.MobilePhone,
                                bindPhone: item.BindingMobile,
                                time: item.CreateTime,
                                note: item.Remark,
                                status: item.Status.Status
                            };
                        });
                        this.page.count = count;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        customerStatusName(row) {
            return this.intentionCustomerStatusList.find(val => val.id === row.status)?.name;
        },
        // 处理查询按钮
        handleSearch() {
            this.queryIntentionCustomerCenterData();
        },
        // 处理导出按钮
        handleExport() {
            downloadIntentionCustomerCenterListAction()
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '导出成功',
                        center: true
                    });
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理编辑备注按钮
        handleEditNoteBtn(row) {
            this.editNoteData = row;
            this.dialogEditNoteVisible = true;
        },
        // 处理更改状态按钮
        handleChangeStatus(row) {
            let { id, status } = row;
            this.changeStatusData = {
                id,
                status
            };
            this.dialogChangeStatusVisible = true;
        },
        // 处理删除按钮
        handleDeleteBtn(row) {
            let params = {
                ID: row.id
            };
            this.$confirm('是否进行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    IntentionCustomerCenterDeleteAction(params)
                        .then(res => {
                            let { code } = res;
                            if (code === 200) {
                                this.queryIntentionCustomerCenterData();
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                    center: true
                                });
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryIntentionCustomerCenterData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.queryIntentionCustomerCenterData();
        },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        }
    }
};
</script>

<style lang="scss" scoped>
.intention-customer-center-page {
    box-sizing: border-box;
    padding: 15px;
    background-color: #fff;

    .intention-customer-center-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-left {

            .el-select,
            .el-input {
                margin-right: 20px;
            }

            .el-input {
                width: 200px;
            }
        }

        .header-right {
            span {
                position: relative;
                font-size: 18px;
            }
        }
    }

    .intention-customer-center-table {
        margin-top: 30px;

        .blue-color {
            color: #409eff;
        }
    }
}
</style>
