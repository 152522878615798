import $http from '@/utils/http.js';

/**
 * 入驻意向客户列表
 * @param data
 */
export function getIntentionCustomerCenterList(data) {
    return $http.get('/customcenter/index', data, 'loadingDiv');
}

/**
 * 入驻意向客户编辑备注操作
 * @param data
 */
export function editIntentionCustomerCenterNoteAction(data) {
    return $http.post('/customcenter/remark', data, 'loadingDiv');
}

/**
 * 更改入驻意向客户状态操作
 * @param data
 */
export function changeIntentionCustomerCenterStatusAction(data) {
    return $http.post('/customcenter/change', data, 'loadingDiv');
}

/**
 * 导出入驻意向客户列表操作
 * @param data
 */
export function downloadIntentionCustomerCenterListAction(data) {
    return $http.exports('/customcenter/export', data, 'loadingDiv', '入驻意向客户信息列表.xlsx');
}

/**
 * 入驻意向客户列表删除操作
 * @param data
 */
export function IntentionCustomerCenterDeleteAction(data) {
    return $http.post('/customcenter/delete', data, 'loadingDiv');
}
