<template>
    <div class="change-status-page">
        <el-radio-group v-model="statusForm.status" size="small">
            <el-radio-button v-for="status in statusList" :label="status.id" :key="status.id">{{
            status.name
            }}</el-radio-button>
        </el-radio-group>
        <div class="footer-wrap">
            <el-button size="small" type="primary" @click="handleConfirmBtn">确认</el-button>
            <el-button size="small" type="info" @click="$emit('closeDialog', false)">取消</el-button>
        </div>
    </div>
</template>

<script>
import { changeIntentionCustomerCenterStatusAction } from '@/common/api/customerCenter/intentionCustomerCenter.js';
export default {
    name: 'changStatusDialog',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        intentionCustomerStatusList: {
            type: Array,
            default: () => []
        },
        changeStatusData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            // 状态列表
            statusList: this.intentionCustomerStatusList
        };
    },
    computed: {
        statusForm() {
            return this.changeStatusData;
        }
    },
    created() {
        this.statusList.shift();
    },
    methods: {
        // 处理确认按钮
        handleConfirmBtn() {
            let params = {
                ID: this.statusForm.id,
                Status: this.statusForm.status
            };
            changeIntentionCustomerCenterStatusAction(params)
                .then(res => {
                    let { code, msg } = res;
                    if (code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${msg}`,
                            center: true
                        });
                        this.$emit('closeDialog', false);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.change-status-page {
    .el-radio-button {
        margin: 0 20px 20px 0;
        border: 1px solid #dcdfe6;
    }

    .footer-wrap {
        text-align: center;
    }
}
</style>
